import {API_ROOT} from '../../../Constants.js';

const GetHotelFilter = async (city, attributes = []) => {
  const url = new URL(`${API_ROOT}/api/v1/hotels/filter`);
  url.searchParams.append('city', city);
  attributes.forEach(attr => url.searchParams.append('attributes[]', attr));

  console.log('Requesting URL:', url.toString());  // For debugging

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // Add any other headers you need, but exclude Authorization if it's not required for this endpoint
      },
    });

    const responseJson = await response.json();
    const statusCode = response.status;

    return [statusCode, responseJson];
  } catch (error) {
    console.error("Error in GetHotelFilter:", error);
    return [500, { error: "An error occurred while fetching data" }];
  }
};

export default GetHotelFilter;