// Utils
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, useNavigate } from "react-router-dom";
import {DESIGNS, HOTEL_ATTRIBUTES} from './js/Constants.js';

// api Calls
import PostUser from './js/api/v1/PostUser';
import PostEmail from './js/api/v1/PostEmail';
import Post from './js/api/v1/Post'
import Get from './js/api/v1/Get'
import GetHotelFilter from './js/api/v1/ruby/GetHotelFilter';
import { fetchTopHotels, fetchRemoteWorkerHotels, fetchBudgetHotels } from './js/api/v1/ruby/fetchTopHotels';
import { fetchCities } from './js/api/v1/ruby/fetchCities';
import { fetchHotelsByCity } from './js/api/v1/ruby/fetchHotelsByCity'; // Add this import

// Sub Views
import MainFeed from './js/MainFeed'; // Import the MainFeed component
import LandscapeGallery from './LandscapeGallery'; // Import the LandscapeGallery component
import EmailPopup from './EmailPopup'; // Make sure to import the Popup component
import LoginForm from './js/subViews/LoginForm'; // Make sure to import the Popup component

// css
import './styles.css';
import './LandscapeGallery.css'; // Import the CSS file for LandscapeGallery
import { COLORS } from './js/Constants';
import { DARK_THEME_COLORS } from './js/Constants';

function HomePage() {
  
  const [email, setEmail] = useState('');
  const [showInvalidEmailPopup, setShowInvalidEmailPopup] = useState(false);
  const handleEmailChange = (e) => {  // console.log("setting email: ",e);  // console.log("setting email to: ",email); 
    setEmail(e.target.value);  };

  const [submittedEmail, setSubmittedEmail] = useState('');
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate()

  const handleLoginSuccess = () => {  // Redirect to another page after successful login  // console.log("handle login success");
    navigate('/home')
  };
  const handleSignupSuccess = () => { setShowCreatedUserPopup(true);  }

  const [showCreatedUserPopup, setShowCreatedUserPopup] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState(null);

  const handleCloseInvalidEmailPopup = () => { setShowInvalidEmailPopup(false);   };
  const handleShowCreatedUserPopup = () => {  setShowCreatedUserPopup(false);   }

  // handle design scroll selection for landscape gallery
  const handleSelectDesign = (design) => {    setSelectedDesign(design);  };

  useEffect(() => {   // check for user session, if none, request login
    var session = localStorage.getItem('session_id');
    if (session !== null) {   navigate('/Home')   }
    return () => {  };
  }, [0]); 

  const [topHotels, setTopHotels] = useState([]);
  const [remoteWorkerHotels, setRemoteWorkerHotels] = useState([]);
  const [budgetHotels, setBudgetHotels] = useState([]);
  const [isLoading, setIsLoading] = useState({
    top: true,
    remoteWorker: true,
    budget: true
  });
  const [errors, setErrors] = useState({
    top: null,
    remoteWorker: null,
    budget: null
  });

  useEffect(() => {
    const fetchHotels = () => {
      fetchTopHotels()
        .then(response => {
          setTopHotels(response || []);
        })
        .catch(error => {
          console.error('Error fetching top hotels:', error);
          setErrors(prev => ({ ...prev, top: 'Failed to load top hotels.' }));
        })
        .finally(() => {
          setIsLoading(prev => ({ ...prev, top: false }));
        });

      fetchRemoteWorkerHotels()
        .then(response => {
          setRemoteWorkerHotels(response || []);
        })
        .catch(error => {
          console.error('Error fetching remote worker hotels:', error);
          setErrors(prev => ({ ...prev, remoteWorker: 'Failed to load remote worker hotels.' }));
        })
        .finally(() => {
          setIsLoading(prev => ({ ...prev, remoteWorker: false }));
        });

      fetchBudgetHotels()
        .then(response => {
          setBudgetHotels(response || []);
        })
        .catch(error => {
          console.error('Error fetching budget hotels:', error);
          setErrors(prev => ({ ...prev, budget: 'Failed to load budget hotels.' }));
        })
        .finally(() => {
          setIsLoading(prev => ({ ...prev, budget: false }));
        });
    };

    fetchHotels();
  }, []);

  const handleSubmitEmail = (e) => {
    e.preventDefault(); 
    // check email for validity
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      // check if email exists as user in rails
      var emailJson =  JSON.stringify({ user: {email: email} })
      Post('users/email_exists', emailJson)
      .then((result) => {
        if(result) {
          // show password popup
          // console.log("user exists: ",email)
          setShowLoginPopup(true);
        } else {
          // send user creation request to rails, then signup email verification to user from api // console.log("valid email: ", email);
          PostEmail(JSON.stringify({"email": email, "password": '012345678910'}))
          .then((response) => {
            if (response.status) {
              return Promise.resolve(setShowCreatedUserPopup(true));
            }
        })
        }
      })
    } else {
      // Show the popup if the email is invalid
      setShowInvalidEmailPopup(true);
    }
  };

  const [isToggled, setIsToggled] = useState(false);

  const toggleButton = () => {
    setIsToggled(!isToggled);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [cities, setCities] = useState([]);
  const [cityError, setCityError] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const searchRef = useRef(null);

  useEffect(() => {
    const loadCities = async () => {
      try {
        const response = await fetchCities();
        // Extract only the cities array from the response
        const fetchedCities = response[1];
        setCities(fetchedCities);
      } catch (error) {
        console.error('Error loading cities:', error);
        setCityError('Failed to load cities. Please try again later.');
      }
    };
    loadCities();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setShowDropdown(true);
  };

  const [cityHotels, setCityHotels] = useState([]);
  const [isCityHotelsLoading, setIsCityHotelsLoading] = useState(false);
  const [cityHotelsError, setCityHotelsError] = useState(null);

  const fetchHotelsForCity = async (city) => {
    setIsCityHotelsLoading(true);
    setCityHotelsError(null);
    try {
      const hotels = await fetchHotelsByCity(city);
      if (Array.isArray(hotels) && hotels.length === 0) {
        setCityHotelsError(`No hotels found for ${city}`);
      } else {
        setCityHotels(hotels);
      }
    } catch (error) {
      console.error('Error fetching hotels for city:', error);
      setCityHotelsError(error.message || 'Failed to load hotels for this city. Please try again later.');
    } finally {
      setIsCityHotelsLoading(false);
    }
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setSearchTerm('');
    setShowDropdown(false);
    fetchHotelsForCity(city);
  };

  const handleClearCity = () => {
    setSelectedCity(null);
  };

  const galleryData = [
    {
      heading: 'Top Rated Hotels',
      images: [
        { id: 1, text: 'Hotel 1' },
        { id: 2, text: 'Hotel 2' },
        { id: 3, text: 'Hotel 3' },
        { id: 4, text: 'Hotel 4' },
        { id: 5, text: 'Hotel 5' },
      ],
    },
    {
      heading: 'Locations with the Most Top Rated Hotels',
      images: [
        { id: 1, text: 'Location 1' },
        { id: 2, text: 'Location 2' },
        { id: 3, text: 'Location 3' },
        { id: 4, text: 'Location 4' },
        { id: 5, text: 'Location 5' },
      ],
    },
    {
      heading: 'Best for Walkability',
      images: [
        { id: 1, text: 'Walkable Area 1' },
        { id: 2, text: 'Walkable Area 2' },
        { id: 3, text: 'Walkable Area 3' },
        { id: 4, text: 'Walkable Area 4' },
        { id: 5, text: 'Walkable Area 5' },
      ],
    },
    {
      heading: 'Best for Cleanliness',
      images: [
        { id: 1, text: 'Clean Area 1' },
        { id: 2, text: 'Clean Area 2' },
        { id: 3, text: 'Clean Area 3' },
        { id: 4, text: 'Clean Area 4' },
        { id: 5, text: 'Clean Area 5' },
      ],
    },
  ];

  const galleryStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '10px',
    maxWidth: '1200px', // Add this line to limit the width
    margin: '0 auto', // Add this line to center the gallery
  };

  const imageContainerStyle = {
    position: 'relative',
    overflow: 'hidden',
    height: '200px',
    borderRadius: '10px',
    backgroundColor: '#ccc', // Placeholder background color
  };

  const textOverlayStyle = {
    position: 'absolute',
    bottom: '0',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    width: '100%',
    textAlign: 'center',
    padding: '10px',
    fontSize: '16px',
    boxSizing: 'border-box',
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState(['value']);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleAttributeClick = (attribute) => {
    setSelectedAttributes(prev => {
      if (prev.length === 1 && prev.includes(attribute)) {
        // If there's only one attribute selected and it's being clicked, don't change anything
        return prev;
      }
      if (attribute === 'value') {
        return prev.includes('value') ? prev.filter(a => a !== 'value') : ['value', ...prev];
      }
      return prev.includes(attribute) 
        ? prev.filter(a => a !== attribute)
        : [...prev, attribute];
    });
  };

  useEffect(() => {
    const filterHotels = async () => {
      if (selectedCity) {
        try {
          let statusCode, responseData;
          if (selectedAttributes.length > 0) {
            [statusCode, responseData] = await GetHotelFilter(selectedCity, selectedAttributes);
          } else {
            responseData = await fetchHotelsByCity(selectedCity);
          }
          console.log('API response:', statusCode, responseData);
          
          if (Array.isArray(responseData)) {
            setCityHotels(responseData);
          } else if (responseData && typeof responseData === 'object') {
            if (responseData.message) {
              setCityHotelsError(responseData.message);
            } else {
              console.error('Unexpected API response format:', responseData);
              setCityHotelsError('Unexpected response from server');
            }
            setCityHotels([]);
          } else {
            console.error('Unexpected API response format:', responseData);
            setCityHotelsError('Unexpected response from server');
            setCityHotels([]);
          }
        } catch (error) {
          console.error('Error filtering hotels:', error);
          setCityHotelsError('Failed to fetch hotels. Please try again.');
          setCityHotels([]);
        }
      } else {
        setCityHotels([]);
        setCityHotelsError(null);
      }
    };

    filterHotels();
  }, [selectedCity, selectedAttributes]);

  console.log('selectedCity:', selectedCity);
  console.log('selectedAttributes:', selectedAttributes);
  console.log('cityHotels:', cityHotels);

  return (
    <div style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} className="homepage">
      <header style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} >
        <h1 style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>HotelsGrade</h1>
        <nav>
          <a style={{color: DARK_THEME_COLORS.TEXT_COLOR}} href="#">Home</a>
        </nav>
      </header>
      <div style={{ display: 'flex' }}> {/* Add this wrapper div */}
        <aside style={{
          width: '250px',
          backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR,
          padding: '20px',
          color: DARK_THEME_COLORS.TEXT_COLOR,
          position: 'sticky',
          top: 0,
          height: '100vh',
          overflowY: 'auto'
        }}>
          <h3>Filter by Attributes</h3>
          {HOTEL_ATTRIBUTES.map(attribute => (
            <button
              key={attribute}
              onClick={() => handleAttributeClick(attribute)}
              style={{
                display: 'block',
                margin: '5px 0',
                padding: '5px',
                backgroundColor: selectedAttributes.includes(attribute) ? '#4CAF50' : 'transparent',
                color: selectedAttributes.includes(attribute) ? 'white' : DARK_THEME_COLORS.TEXT_COLOR,
                border: 'none',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left'
              }}
            >
              {attribute}
            </button>
          ))}
        </aside>
        <main style={{ 
          backgroundColor: DARK_THEME_COLORS.BACKGROUND_DARK, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          flex: 1 // Add this to make the main content take up remaining space
        }}>
          <div style={{ flex: 1, width:'50%', flexDirection:'column'}}>
            <div style={{ flex: 1}}>
              <h2 style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>Choose the right place for you.</h2>
            </div>
            <div style={{ flex: 1}}>
              <h5 style={{color: DARK_THEME_COLORS.TEXT_COLOR}} >Match your mood, find your stay.</h5>
            </div>
          </div>
          <div id="video-placeholder" style={{
            width: '480px',
            height: '300px',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #ccc',
            borderRadius: '8px',
          }}>
            <p style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '18px',
              color: '#666',
            }}>
              Video will be embedded here
            </p>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px',
          }}>
            <button
            onClick={toggleFilter}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              fontWeight: 'bold',
              border: 'none',
              borderRadius: '20px',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              backgroundColor: isFilterOpen ? '#4CAF50' : '#f0f0f0',
              color: isFilterOpen ? 'white' : 'black',
              boxShadow: isFilterOpen 
                ? '0 0 10px rgba(76, 175, 80, 0.5)' 
                : '0 2px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            {isFilterOpen ? 'Close Filter' : 'Filter'}
          </button>
          {/* search bar to search a location  */}
          <div style={{
            display: 'flex',
            justifyContent: 'left',
            margin: '20px 0',
            position: 'relative'
          }} ref={searchRef}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px',
              border: '2px solid #ccc',
              borderRadius: '4px',
              width: '300px',
            }}>
              {selectedCity && (
                <div style={{
                  backgroundColor: '#e0e0e0',
                  padding: '2px 5px',
                  borderRadius: '3px',
                  marginRight: '5px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  {selectedCity}
                  <button onClick={handleClearCity} style={{
                    marginLeft: '5px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer'
                  }}>
                    ×
                  </button>
                </div>
              )}
              <input
                type="text"
                placeholder={selectedCity ? "" : "Search a Location..."}
                value={searchTerm}
                onChange={handleSearch}
                onFocus={() => setShowDropdown(true)}
                style={{
                  padding: '5px',
                  fontSize: '16px',
                  border: 'none',
                  outline: 'none',
                  flex: 1
                }}
              />
            </div>
            {showDropdown && (
              <div style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '300px',
                maxHeight: '200px',
                overflowY: 'auto',
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: '0 0 4px 4px',
                zIndex: 1000
              }}>
                {cityError ? (
                  <div style={{ padding: '10px', color: 'red' }}>{cityError}</div>
                ) : (
                  cities.filter(city => city.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map((city, index) => (
                      <div
                        key={index}
                        onClick={() => handleCitySelect(city)}
                        style={{
                          padding: '10px',
                          cursor: 'pointer',
                          borderBottom: '1px solid #eee',
                          ':hover': {
                            backgroundColor: '#f0f0f0'
                          }
                        }}
                      >
                        {city}
                      </div>
                    ))
                )}
              </div>
            )}
          </div>
          </div>

          <div style={{ width: '100%', maxWidth: '1200px' }}>
            {/* Filtered Hotels in City Gallery */}
            {selectedCity && (
              <div className="city-hotels">
                <h2>Hotels in {selectedCity}</h2>
                {isCityHotelsLoading ? (
                  <div>Loading hotels for {selectedCity}...</div>
                ) : cityHotelsError ? (
                  <div style={{ color: 'red' }}>{cityHotelsError}</div>
                ) : cityHotels.length > 0 ? (
                  <div style={galleryStyle}>
                    {cityHotels.map((hotel, index) => (
                      <div key={`hotel-${hotel.id || index}`} style={imageContainerStyle}>
                        {hotel.imageUrl ? (
                          <img src={hotel.imageUrl} alt={hotel.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        ) : (
                          <div style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}></div>
                        )}
                        <div style={textOverlayStyle}>
                          <h3>{hotel.name || 'Unnamed Hotel'}</h3>
                          {selectedAttributes.includes('value') && hotel.value != null && 
                            <p key={`value-${hotel.id || index}`}>Value: {hotel.value}</p>
                          }
                          {selectedAttributes
                            .filter(attr => attr !== 'value')
                            .map(attr => (
                              <p key={`${hotel.id || index}-${attr}`}>{attr}: {hotel[attr] != null ? hotel[attr] : 'N/A'}</p>
                            ))
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No hotels found in {selectedCity} with the selected attributes.</div>
                )}
              </div>
            )}

            {/* Top Rated Hotels Gallery */}
            {isLoading.top ? (
              <div>Loading top hotels...</div>
            ) : errors.top ? (
              <div style={{ color: 'red' }}>{errors.top}</div>
            ) : topHotels.length > 0 && (
              <div className="top-rated-hotels">
                <h2>Top Rated Hotels</h2>
                <div style={galleryStyle}>
                  {topHotels.map((hotel) => (
                    <div key={hotel.id} style={imageContainerStyle}>
                      {hotel.imageUrl ? (
                        <img src={hotel.imageUrl} alt={hotel.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      ) : (
                        <div style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}></div>
                      )}
                      <div style={textOverlayStyle}>
                        <h3>{hotel.name}</h3>
                        <p>Cleanliness: {hotel.cleanliness}</p>
                        <p>Walkability: {hotel.walkability}</p>
                        <p>Waterfront: {hotel.waterfront}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Remote Worker Hotels Gallery */}
            {isLoading.remoteWorker ? (
              <div>Loading remote worker hotels...</div>
            ) : errors.remoteWorker ? (
              <div style={{ color: 'red' }}>{errors.remoteWorker}</div>
            ) : remoteWorkerHotels.length > 0 && (
              <div className="remote-worker-hotels">
                <h2>Best Hotels for Remote Workers</h2>
                <div style={galleryStyle}>
                  {remoteWorkerHotels.map((hotel) => (
                    <div key={hotel.id} style={imageContainerStyle}>
                      {hotel.imageUrl ? (
                        <img src={hotel.imageUrl} alt={hotel.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      ) : (
                        <div style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}></div>
                      )}
                      <div style={textOverlayStyle}>
                        <h3>{hotel.name}</h3>
                        <p>Walkability: {hotel.walkability}</p>
                        <p>Public Transportation: {hotel.public_transportation}</p>
                        <p>WiFi: {hotel.wifi}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Budget Hotels Gallery */}
            {isLoading.budget ? (
              <div>Loading budget hotels...</div>
            ) : errors.budget ? (
              <div style={{ color: 'red' }}>{errors.budget}</div>
            ) : budgetHotels.length > 0 && (
              <div className="budget-hotels">
                <h2>Best Budget Hotels</h2>
                <div style={galleryStyle}>
                  {budgetHotels.map((hotel) => (
                    <div key={hotel.id} style={imageContainerStyle}>
                      {hotel.imageUrl ? (
                        <img src={hotel.imageUrl} alt={hotel.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      ) : (
                        <div style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}></div>
                      )}
                      <div style={textOverlayStyle}>
                        <h3>{hotel.name}</h3>
                        <p>Price: {hotel.price}</p>
                        <p>Value: {hotel.value}</p>
                        <p>Walkability: {hotel.walkability}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* No hotels found message */}
            {!isLoading.top && !isLoading.remoteWorker && !isLoading.budget &&
             topHotels.length === 0 && remoteWorkerHotels.length === 0 && budgetHotels.length === 0 && !selectedCity && (
              <div>No hotels found.</div>
            )}
          </div>
        </main>
      </div>
      <footer  style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} >
        <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} >&copy; 2024 HotelsGrade. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;