// In js/api/v1/ruby/fetchHotelsByCity.js
import {API_ROOT} from '../../../Constants.js';


export const fetchHotelsByCity = async (city) => {
    try {
      const response = await fetch(API_ROOT + `/api/v1/hotels/by_city?city=${encodeURIComponent(city)}`);
      console.log('Fetch hotels by city response:', response);
      
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        console.log('Fetch hotels by city data:', data);
        
        if (!response.ok) {
          if (response.status === 404) {
            return []; // Return an empty array if no hotels found
          }
          throw new Error(data.error || 'An error occurred while fetching hotels');
        }
        
        return data;
      } else {
        // If the response is not JSON, log the text content for debugging
        const textContent = await response.text();
        console.error('Received non-JSON response:', textContent);
        throw new Error('Received non-JSON response from server');
      }
    } catch (error) {
      console.error('Error fetching hotels by city:', error);
      throw error;
    }
  };