// common application constants
import testShot from './../images/testShot.jpg';

export const HOTEL_ATTRIBUTES = [
  'name', 'address', 'value', 'price', 'cleanliness', 'parking', 'noise', 'check-in', 
  'room-size', 'bathroom', 'wifi', 'pets', 'pool', 'walkability', 'restaurants', 
  'public-transportation', 'airports', 'bug-free', 'renovation-age', 'room-types', 
  'breakfast', 'waterfront'
];

export const DESIGNS = [
  { name: 'Modern Garden', image: testShot }, //pineWatt
  { name: 'Traditional Landscape', image: testShot },
  { name: 'Zen Retreat', image: testShot }, //'zen-retreat.jpg'
  { name: 'Cottage Garden', image: testShot },
  { name: 'Desert Oasis', image: testShot },
  { name: 'Tropical Paradise', image: testShot },
  { name: 'English Country Garden', image: testShot },
  { name: 'Mediterranean Courtyard', image: testShot },
  { name: 'Asian Zen Garden', image: testShot },
  { name: 'Coastal Escape', image: testShot },
  { name: 'Urban Rooftop Garden', image: testShot },
  { name: 'Wildflower Meadow', image: testShot },
  { name: 'Formal Courtyard', image: testShot },
  { name: 'Xeriscape Garden', image: testShot },
];

export const API_ROOT = process.env.NODE_ENV === 'production'
  ? 'https://api.hotelsgrade.com'
  : 'http://localhost:3000';

export const VARS = {
  apiPath: 'http://localhost:4000/api/',
  apiRoot: 'http://localhost:4000/',
}


// export const API_WS_ROOT = 'ws://localhost:4000/cable';
export const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const COLORS = {
  white: '#fff',
  black: '#000',
  blue: 'blue',
  // your colors
  darkGreen: '#004550',
  compliment: '#02828d', //bahama blue

  lightGreen: '#42B491', //this is actually light blue
  green: '#3DA2AE', //this is turquiose not green
  turquiose: '#3DA2AE', // lighter bahama blue
  beige: '#DAD09D',
  orange: '#EBA31D',
  red: '#D94929',
  errorRed: '#f71b1b',
}

export const DARK_THEME_COLORS = {
  PRIMARY_COLOR: '#333333',
  PRIMARY_DARK: '#2F2F2F',
  PRIMARY_LIGHT: '#444444',
  SECONDARY_COLOR: '#666666',
  SECONDARY_DARK: '#555555',
  SECONDARY_LIGHT: '#777777',
  ACCENT_COLOR: '#8B9467',
  ACCENT_DARK: '#754975',
  ACCENT_LIGHT: '#9A9A9A',
  BACKGROUND_COLOR: '#1A1D23',
  BACKGROUND_DARK: '#14151A',
  BACKGROUND_LIGHT: '#23272E',
  TEXT_COLOR: '#FFFFFF',
  TEXT_DARK: '#CCCCCC',
  TEXT_LIGHT: '#999999',
  HOVER_COLOR: '#444444',
  FOCUS_COLOR: '#666666',
};

// export const DARK_THEME_COLORS = {
//   white: '#fff',
//   black: '#000',
//   blue: 'blue',
//   // your colors
//   darkGreen: '#004550',
//   compliment: '#02828d', //bahama blue

//   lightGreen: '#42B491', //this is actually light blue
//   green: '#3DA2AE', //this is turquiose not green
//   turquiose: '#3DA2AE', // lighter bahama blue
//   beige: '#DAD09D',
//   orange: '#EBA31D',
//   red: '#D94929',
//   errorRed: '#f71b1b',
//   // header background rgb(51, 51, 51)
//   // header text rgb(245, 245, 245)
//   // page text 
//   // page background
//   // p3 text color
//   // p1 text color
// }


export const P1 = {
  // red: '#be3144', //red
  // black: '#303841', //black
  // gray: '#3a4750', //gray
  // white: '#d3d6db', //white
  a: '#be3144', //red rgb(190,49,68)
  b: '#303841', //black rgb(48,56,65)
  c: '#3a4750', //gray rgb(58,71,80)
  d: '#d3d6db', //white rgb(211,214,219)
}

export const P2 = {
  a: '#f67280', //pink
  b: '#35477d', //blue
  c: '#6c5b7b', //purple
  d: '#c06c84', //muted pink
}

export const TEST = {
  apiURL: 'http://localhost:3000',
  // Stripe developer API Secret Key, used in Subscriptions 
  stripeClientSecret: 'sk_test_51Nds8hEQV7VZRFF8FimtCVZoWs4qsn6uT4QDxFuwQDYRoizYpFSViCITMsKAjkfEba3vtxsHuAVrkewrfKbBCsuA00RcGYoGkb',
  // Stripe developer API Publishable Key, used in Subscriptions 
  stripePublishableKey: 'pk_test_51Nds8hEQV7VZRFF8uJ5kxSrbfBw5AUt7fJztw7eJInp8BhFPjcYcf0DAR870d2vEd5uKE1eyN82rFeG26M0EyOFa00MnDktmzE',
  // url https://api.enviromentai.com/api/v1/stripe-webhook , used in rails only?
  stripeWebhookSigningSecret: 'whsec_0YSDn9ywltnC6xjO0iVjc6KhViyDRo99',
}

export const PROD = {
  apiURL: 'https://api.hotelsgrade.com',
  // Stripe developer API Secret Key
  stripeClientSecret: 'pk_live_51Nds8hEQV7VZRFF8PzmqeQhRdWsF3PON4vb90JqUv8VVs2nwQ4HTpucTrJsASsaauKImSfFrkhsryQTKF3kY7Wrn00DEOQjncn',
  // Stripe developer API Publishable Key
  // stripePublishableKey: ''
  // stripeWebhookSigningSecret:/
}


