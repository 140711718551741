// import * as React from 'react';
import {API_ROOT} from '../../Constants.js';


const Get = async (url, authorization) => {

  const GetJson = async() => {
    try {
      const fullUrl = url.includes('http') ? url : API_ROOT + '/api/v1/' + url;
      // console.log("decided full Url: ",fullUrl);
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: authorization},
      });
      const responseJson = await response.json();
      // console.log("Get Response: ",responseJson);
      const statusCode = response.status;
      return Promise.all([statusCode, responseJson])
    } catch (error){
      console.log("Error", error);
    }
  }
  return GetJson();
  
  };
export default Get;