import {API_ROOT} from '../../../Constants.js';
import Get from '../Get';

export const fetchCities = async () => {
  try {
    const response = await Get(API_ROOT + '/api/v1/cities');
    console.log('fetch cities response:', response);
    return response;
  } catch (error) {
    console.error('Error fetching cities:', error);
    return [];
  }
};