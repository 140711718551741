import {API_ROOT} from '../../../Constants.js';
import Get from '../Get';

export const fetchTopHotels = async () => {
  try {
    const response = await Get(API_ROOT + '/api/v1/top_hotels');
    console.log('Top hotels response:', response);
    // Return only the hotel data (second element of the array)
    return response[1] || [];
  } catch (error) {
    console.error('Error fetching top hotels:', error);
    throw error;
  }
};

export const fetchRemoteWorkerHotels = async () => {
  try {
    const response = await Get(API_ROOT +'/api/v1/remote_worker_hotels');
    console.log('Remote worker hotels response:', response);
    // Return only the hotel data (second element of the array)
    return response[1] || [];
  } catch (error) {
    console.error('Error fetching remote worker hotels:', error);
    throw error;
  }
};

export const fetchBudgetHotels = async () => {
  try {
    const response = await Get(API_ROOT + '/api/v1/budget_hotels');
    console.log('Budget hotels response:', response);
    // Return only the hotel data (second element of the array)
    return response[1] || [];
  } catch (error) {
    console.error('Error fetching budget hotels:', error);
    throw error;
  }
};